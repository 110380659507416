import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import { IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import '@fontsource/roboto/300.css';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MovingIcon from '@mui/icons-material/Moving';
import SchoolIcon from '@mui/icons-material/School';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const chainupLogo = require('./images/chainup.webp')
const liberalize = require('./images/liberalize.png')
const rdp = require('./images/rdp.png')
const doctorworld = require('./images/doctorworld.png')
const ezCare = require('./images/ezcare.jpeg')
const nus = require('./images/nus.png')
const uol = require('./images/uol.png')
const aws = require('./images/aws.png')
const deeplearningai = require('./images/deeplearningai.png')


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "#1a1328"
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function App() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
  const firebaseConfig = {
    apiKey: "AIzaSyCGQ0tYppWFJkuSxBhOpkH0xVDmX245Vdc",
    authDomain: "project-id.firebaseapp.com",
    databaseURL: "https://project-id.firebaseio.com",
    projectId: "project-id",
    storageBucket: "project-id.appspot.com",
    messagingSenderId: "637908496727",
    appId: "2:637908496727:web:a4284b4c99e329d5",
    measurementId: "G-9VP01NDSXJ"
  };

  const app = initializeApp(firebaseConfig);

  // Initialize Analytics and get a reference to the service
  const analytics = getAnalytics(app);

  useEffect(() => {
    logEvent(analytics, 'page_first_load');
  },[]);


  return (
    <div className="App">
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon fontSize='large'/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Typography variant="h3" component="h3">
            Kenneth Goh
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon fontSize='large'/> : <ChevronRightIcon fontSize='large'/>}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['About', 'Experience', 'Education', 'Certificate'].map((text, index) => (
                <ListItem key={text} disablePadding>
                    <ListItemButton component="a" href={`#${text.toLowerCase()}`}>
                      <ListItemIcon fontSize='large'>
                        {index === 0 && <AccountCircleIcon fontSize='large' sx={{color: "#07b492"}} />}
                        {index === 1 && <MovingIcon fontSize='large' sx={{color: "#00dfff"}}/>}
                        {index === 2 && <SchoolIcon fontSize='large' sx={{color: "orange"}}/>}
                        {index === 3 && <VerifiedUserIcon fontSize='large' sx={{color: "red"}}/>}
                      </ListItemIcon>
                      <ListItemText primary={text} primaryTypographyProps={{fontSize: '1em'}} />
                    </ListItemButton>
                </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <section className='about' id="about">
        <div className='transparentBlack'>
        <div className='aboutContainer'>
          <Typography style={{ color: "#fff", fontSize: '3em'}} variant="h1" component="h1">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                Kenneth Goh .
              </Grid>
              <Grid item xs={12} md={4}>
                吳振豪 .
              </Grid>
              <Grid item xs={12} md={4}>
                恭一 (きょういち)
              </Grid>
            </Grid>
          </Typography>
          <Typography style={{ color: "#fff"}} variant="h5" component="h5">
            <u>Computer Science</u> / <u>Distributed Technologist</u> / <u>Solutions Architect</u> / <u>Economist</u>
          </Typography>
          <br/><br/>
          <Stack direction="row" spacing={1} sx={{ width: "50%", margin: "0 auto"}}>
            <Grid container spacing={2}>
              <Grid item xs={4} md={4}>
                <IconButton style={{ color: "#fff"}} aria-label="twitter" onClick={(e) => {
                  window.open("https://twitter.com/Kenneth24279934", "_blank")
                }}>
                  <TwitterIcon fontSize='large'/>
                </IconButton>
              </Grid>
              <Grid item xs={4} md={4}>
                <IconButton style={{ color: "#fff"}} aria-label="github">
                  <GitHubIcon fontSize='large' onClick={(e) => {
                  window.open("https://github.com/DarkArtistry", "_blank")
                }}/>
                </IconButton>
              </Grid>
              <Grid item xs={4} md={4}>
                  <IconButton style={{ color: "#fff"}} href={`mailto:'kenneth.gzhao@outlook.com'`} aria-label="email" onClick={(e) => {
                    window.open('mailto:kenneth.gzhao@outlook.com', '_blank');
                  }}>
                    <EmailIcon fontSize='large'/>
                  </IconButton>
              </Grid>
            </Grid>
          </Stack>
        </div>
        </div>
      </section>
      <br/><br/>
      <section className='experience' id="experience">
        <div>
          <Grid container spacing={2} >
            <Grid item xs={12} md={12}>
              <Typography sx={{width: "100%"}} variant="h2" component="h2">
                Experience
              </Typography>
            </Grid>
          </Grid>
        </div>
        <br/><br/>
        <div>
          <Paper elevation={6} sx={{width: "80%", margin: '0 auto'}}>
            <List >
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={4} className={'company'}>
                    <img src={chainupLogo} style={{maxHeight: '150px', maxWidth: '100%'}} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: 700, marginTop: "1em" }} variant="h4" component="h4">
                      ChainUp
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={'company'}>
                    <Typography style={{ fontWeight: 500, marginTop: "1em" }} variant="h5" component="h5">
                      BlockChain Engineer
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider light />
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={12}>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                      As a blockchain engineer at Chain Up, I design and develop multiple blockchain projects, including liquid staking protocols and aggregator projects. I have implemented community governance mechanisms to ensure decentralized decision-making and transparency, while conducting research on the latest blockchain developments and trends, with a focus on scalability, security, and interoperability. My technical skills include proficiency in Solidity, Go-lang, Rust, JavaScript and related web3 libraries, experience with different blockchain protocols, knowledge of cryptographic primitives and more. I have built and maintained smart contract systems on multiple blockchain platforms such as Ethereum, Solana, Cosmos, and more. My responsibility also includes working on projects involving token economics, token issuance, token swaps, liquidity provision and more. I have collaborated with cross-functional teams, including product managers, designers, and developers, to deliver high-quality blockchain solutions and developed and deployed dApps on various blockchain networks and integrated with other systems such as IPFS, oracle, and off-chain data storage. I also have experience in maintaining node infrastructure for multiple blockchain networks, including setup, monitoring, and troubleshooting. 
                    </Typography>
                    <br/>
                    <Typography>
                      2023-Present
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Paper>
          <br/><br/>
          <Paper elevation={6} sx={{width: "80%", margin: '0 auto'}}>
            <List >
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={4} className={'company'}>
                    <img src={liberalize}  style={{maxHeight: '150px', maxWidth: '100%'}}/>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: 700, marginTop: "1em" }} variant="h4" component="h4">
                      Liberalize
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={'company'}>
                    <Typography style={{ fontWeight: 500, marginTop: "1em" }} variant="h5" component="h5">
                      Co-Founder
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider light />
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={12}>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                    As a co-founder of Liberalize, a MAS regulated payments service provider, I have played a key role in designing and developing an entire payment system with automated accounting and settlements system catered for all timezones, that could reach 8000 transactions per seconds at peak. Additionally, I have also designed and developed multiple marketplace solutions that allow consumers to drag and drop to design and launch an online platform, which can scale much better than Shopify. My expertise extends to front-end development where I have applied multiple 3D design and animations. My skillset includes proficiency in languages such as Golang, Javascript, PHP, Java, Python, and Ruby.
                    </Typography>
                    <br/>
                    <Typography>
                      2021-Present
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Paper>
          <br/><br/>
          <Paper elevation={6} sx={{width: "80%", margin: '0 auto'}}>
            <List >
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={4} className={'company'}>
                    <img src={rdp}  style={{maxHeight: '120px', maxWidth: '100%'}}/>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: 700, marginTop: "1em" }} variant="h4" component="h4">
                      RedDot Payment (a PayU company)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={'company'}>
                    <Typography style={{ fontWeight: 500, marginTop: "1em" }} variant="h5" component="h5">
                      Priciple Payments Service Enginner
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider light />
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={12}>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                      As the primary payment service engineer at Reddot Payment, a PayU company, I was responsible for developing a secure end-to-end solution on microservice architecture for core payment services, front-end and Android device integration. I was also responsible for ensuring that the solution passed Payment Industry Audit Level 1 (the highest) standard and for integrating onsite and online software for high net worth clients. I also joined the system-wide migration team, where I took over and solved bugs on legacy systems and created a data pipeline for data warehousing purposes to Snowflake. Additionally, I also took over backend Golang microservices, and all the architecture was built with automated deployment capabilities. My skillset includes proficiency in languages such as Golang, Javascript, PHP, Java, and Python.
                    </Typography>
                    <br/>
                    <Typography>
                      2019-2021
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Paper>
          <br/><br/>
          <Paper elevation={6} sx={{width: "80%", margin: '0 auto'}}>
            <List >
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={4} className={'company'}>
                    <img src={doctorworld}  style={{maxHeight: '120px', maxWidth: '100%'}}/>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: 700, marginTop: "1em" }} variant="h4" component="h4">
                      DoctorWorld (affiliated to ezCare)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={'company'}>
                    <Typography style={{ fontWeight: 500, marginTop: "1em" }} variant="h5" component="h5">
                      Technical Lead
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider light />
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={12}>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                    As a developer, I led the development of the backend NodeJS framework, front-end SPA web application, managed a team of 5 engineers and 2 designers for the mobile application and some NodeJS microservices, which were mainly monolith architecture, designed database schemas for various hospital specialties and medicines for secure end-to-end medical ecosystem integration, worked with hospitals, directors, doctors, and their engineering teams to integrate into their ecosystem, set up on-site telemedicine stations and completed a government sandbox for a telemedicine project. Additionally, I also did white labeling for private hospitals using Javascript as the primary language.
                    </Typography>
                    <br/>
                    <Typography>
                      2018-2019
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Paper>
          <br/><br/>
          <Paper elevation={6} sx={{width: "80%", margin: '0 auto'}}>
            <List >
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={4} className={'company'}>
                    <img src={ezCare}  style={{maxHeight: '120px', maxWidth: '100%'}}/>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: 700, marginTop: "1em" }} variant="h4" component="h4">
                      ezCare
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={'company'}>
                    <Typography style={{ fontWeight: 500, marginTop: "1em" }} variant="h5" component="h5">
                      Software Engineer
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider light />
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={12}>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                      As the Principal Software Engineer at ezcare, I was responsible for spearheading the development of new features and the maintenance of the backend Node.js service, frontend SPA, iOS and Android native mobile applications. I demonstrated technical expertise by building Single Sign On services and continuously improving the core booking service through frequent updates.
                    </Typography>
                    <br/>
                    <Typography>
                      2017-2018
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Paper>
        </div>
      </section>
      <br/><br/>
      <section className='education' id="education">
        <div>
          <Grid container spacing={2} >
            <Grid item xs={12} md={12}>
              <Typography sx={{width: "100%"}} variant="h2" component="h2">
                Education
              </Typography>
            </Grid>
          </Grid>
        </div>
        <br/><br/>
        <div>
          <Paper elevation={6} sx={{width: "80%", margin: '0 auto'}}>
            <List >
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={4} className={'company'}>
                    <img src={nus} style={{maxHeight: '150px', maxWidth: '100%'}} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: 700, marginTop: "1em" }} variant="h4" component="h4">
                      National University of Singapore
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={'company'}>
                    <Typography style={{ fontWeight: 500, marginTop: "1em" }} variant="h5" component="h5">
                      Master in Computing (Computer Science)
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider light />
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={12}>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                      CS5242 Neural Networks and Deep Learning
                    </Typography>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                      CS5228 Knowledge Discovery and Data Mining
                    </Typography>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                      CS5421 Database Applications Design and Tuning
                    </Typography>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                      CS5446 AI Planning and Decision Making
                    </Typography>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                      CS5229 Advanced Computer Networks
                    </Typography>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                      CS5239 Computer System Performance Analysis
                    </Typography>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                      CS5223 Distributed Systems
                    </Typography>
                    <Typography sx={{textAlign: 'justify', fontSize: '1em'}}>
                      CS5250 Advanced Operating Systems
                    </Typography>
                    <br/>
                    <Typography>
                      2021-2023&nbsp;CAP: 4.X
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Paper>
          <br/><br/>
          <Paper elevation={6} sx={{width: "80%", margin: '0 auto'}}>
            <List >
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={4} className={'company'}>
                    <img src={uol} style={{maxHeight: '150px', maxWidth: '100%'}} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: 700, marginTop: "1em" }} variant="h4" component="h4">
                      University of London
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={'company'}>
                    <Typography style={{ fontWeight: 500, marginTop: "1em" }} variant="h5" component="h5">
                      Bachelors of Science (Banking & Finance)
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Paper>
          <br/><br/>
        </div>
      </section>
      <section className='certificates' id="certificate">
        <div>
          <Grid container spacing={2} >
            <Grid item xs={12} md={12}>
              <Typography sx={{width: "100%"}} variant="h2" component="h2">
                Certificate
              </Typography>
            </Grid>
          </Grid>
        </div>
        <br/><br/>
        <div>
          <Paper elevation={6} sx={{width: "80%", margin: '0 auto'}}>
            <List >
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={4} className={'company'}>
                    <img src={aws} style={{maxHeight: '150px', maxWidth: '100%'}} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: 700, marginTop: "1em" }} variant="h4" component="h4">
                      Amazon Web Service
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={'company'}>
                    <Typography style={{ fontWeight: 500, marginTop: "1em" }} variant="h5" component="h5">
                      AWS Solutions Architect Associate
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Paper>
          <br/><br/>
          <Paper elevation={6} sx={{width: "80%", margin: '0 auto'}}>
            <List >
              <ListItem >
                <Grid container spacing={2} >
                  <Grid item xs={12} md={4} className={'company'}>
                    <img src={deeplearningai} style={{maxHeight: '150px', maxWidth: '100%'}} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: 700, marginTop: "1em" }} variant="h4" component="h4">
                      DeepLearning.Ai
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={'company'}>
                    <Typography style={{ fontWeight: 500, marginTop: "1em" }} variant="h5" component="h5">
                      Neural Networks and Deep Learning Specialization
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Paper>
          <br/><br/>
        </div>
      </section>
    </div>
  );
}

export default App;
